import React, { FC, useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { ChartData, ChartItem } from "chart.js"
import { COLORS, parseCurrency } from '..';
import moment, { Moment} from "moment";
import {
  IsalesPackagesByDate,
  IsalesServicesByDate
} from "../";
import "./styles.css";

/**
 * X Axis: days
 * Y Axis: Income
 * Orange Line: total income from services COLORS[1]
 * Purple Line: total income from packages COLORS[4]
 * Blue Area: total income COLORS[0]
 */
interface IProps {
  dateTo?: Moment;
  dateFrom?: Moment;
  requestingResults: boolean;
  salesPackagesByDate: IsalesPackagesByDate[];
  salesServicesByDate: IsalesServicesByDate[];
}
const MDGraphIncomeInLines: FC<IProps> = ({
  dateTo,
  dateFrom,
  salesPackagesByDate,
  salesServicesByDate
}) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const chartRef = useRef<ChartItem>();
  const [data, setData] = useState<ChartData>();
  const [chartLabels, setChartLabels] = useState<Moment[]>();

  useEffect(() => {
    if (chartLabels && data && canvasRef.current) {
      if (chartRef.current !== undefined) {
        chartRef.current.destroy && chartRef.current.destroy();
        chartRef.current = undefined;
      }
      chartRef.current = new Chart(
        canvasRef.current,
        {
          type: 'line',
          data: data,
          options: {
            responsive: true,
            scales: {
              x: {
                ticks: {
                  color: "#444",
                  font: {
                    size: 14,
                    family: '"EMPrint", sans-serif',
                  }
                },
                grid: {
                  display: false
                }
              },
              y: {
                position: 'right',
                ticks: {
                  padding: 15,
                  color: "#444",
                  font: {
                    size: 14,
                    family: '"EMPrint", sans-serif',
                  },
                  callback: (value) => {
                    return parseCurrency(typeof value === "string" ? parseFloat(value) : value);
                  }
                },
                grid: {
                  drawBorder: false,
                  color: "#AAA",
                }
              
              }
            },
            color: "#444",
            font: {
              size: 14,
              family: '"EMPrint", sans-serif',
            },
            plugins: {
              legend: {
                // position: 'right',
                labels: {
                  boxWidth: 20,
                  boxHeight: 20,
                  padding: 20,
                  // This more specific font property overrides the global property
                  font: {
                    size: 16,
                    weight: "bold",
                    family: '"EMPrint", sans-serif',
                    lineHeight: 20
                  },
                  usePointStyle: true,
                },
              },
              tooltip: {
                callbacks: {
                  label: (context) =>  parseCurrency(context?.parsed?.y || 0),
                }
              }
            }
          }
        }
      );
    }
  }, [chartLabels, data]);

  useEffect(() => {
    if (dateTo && dateFrom) {
      const dateDiff = dateTo.diff(dateFrom, 'days');
      const labels: Moment[] = [];
      for (let i = 0; i < dateDiff; i++) {
        labels[i] = moment(dateFrom.add(1, 'day'));
      }
      setChartLabels(labels);
    }
  }, [dateTo, dateFrom])

  useEffect(() => {
    if (chartLabels === undefined) return;

    const servicesValues: number[] = [];
    const packagesValues: number[] = [];
    const totalValues: number[] = [];

    chartLabels.forEach((element, index) => {
      const dayServices = salesServicesByDate.find((service) => service.salesDate === element.format("YYYY-MM-DD"));
      servicesValues[index] = dayServices === undefined ? 0 : dayServices.salesDetail.reduce((total, sale) => {
        return total + sale.saleTotal
      }, 0);
      const dayPackages = salesPackagesByDate.find((service) => service.salesDate === element.format("YYYY-MM-DD"));
      packagesValues[index] = dayPackages === undefined ? 0 : dayPackages.salesDetail.reduce((total, sale) => {
        return total + sale.saleTotal
      }, 0);
      totalValues[index] = servicesValues[index] + packagesValues[index];
    });
    setData({
      // X Axis Lables
      labels: chartLabels.map(label => label.format("MM/DD")),
      datasets: [
        {
          label: "Servicios",
          data: servicesValues,
          backgroundColor: COLORS[2].hex,
          borderColor: COLORS[2].hex,
          order: 1
        },
        {
          label: "Paquetes",
          data: packagesValues,
          backgroundColor: COLORS[1].hex,
          borderColor: COLORS[1].hex,
          order: 2
        },
        {
          label: "Total",
          data: totalValues,
          fill: true,
          backgroundColor: COLORS[0].rgba(0.2),
          borderColor: COLORS[0].hex,
          order: 0
        },
      ]
    });
  }, [chartLabels, salesPackagesByDate, salesServicesByDate]);


  return (
    <section className="graphic-box md-graph-income-in-lines">
      <h2>Ingresos</h2>
      <div className="graphic-container" >
        <canvas ref={canvasRef} />
      </div>
    </section>
  )
}

export default MDGraphIncomeInLines;

import React, { FC, useEffect, useState } from 'react';
import { CkLink } from "../../../../CkUI";

import "./styles.css";
import { Workshop } from "shared";
import { useSelector } from "react-redux";
import { getWorkshops, getWorkshopSelected } from "../../../../stores";

interface IProps {}
const MDActions: FC<IProps> = ({}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const workshops: Workshop[] = useSelector(getWorkshops);
  const varWorkshopSelected: number = useSelector(getWorkshopSelected);

  useEffect(() => {
    setIsEnabled(workshops[varWorkshopSelected].isEnabled);
  }, [varWorkshopSelected]);

  return (
    <section className="md-actions">
      <h1>Tablero general</h1>
      {
        isEnabled && (
          <CkLink to="/agregar-cita" target="_blank" >
            + Agregar una cita
          </CkLink>
        )
      }
    </section>
  )
}

export default MDActions;

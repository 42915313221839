import React, { FC, useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { ChartData, ChartItem } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  COLORS,
  parsePercentage,
  ITotalAppointments
} from '..';
import "./styles.css";

interface IProps {
  requestingResults: boolean;
  totalAppointments?: ITotalAppointments;
}
const MDGraphAppointmentBars: FC<IProps> = ({
  requestingResults,
  totalAppointments
}) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const chartRef = useRef<ChartItem>();
  const [data, setData] = useState<ChartData>();
  const maximumValueRef = useRef<number>(100);

  useEffect(() => {
    if (data && canvasRef.current) {
      if (chartRef.current !== undefined) {
        chartRef.current.destroy && chartRef.current.destroy();
      }
      chartRef.current = new Chart(
        canvasRef.current,
        {
          type: 'bar',
          data: data,
          options: {
            indexAxis: 'y',
            // Elements options apply to all of the options unless overridden in a dataset
            // In this case, we are setting the border of each horizontal bar to be 2px wide
            elements: {
              bar: {
                borderWidth: 2,
              }
            },
            color: "#444",
            font: {
              size: 14,
              family: '"EMPrint", sans-serif',
            },
            responsive: true,
            scales: {
              x: {
                stacked: true,
                ticks: {
                  display: false
                },
                grid: {
                  color: (context) => context?.tick?.value === 0 ? "#444" : 'transparent',
                  drawBorder: false
                },
                min: -1 * maximumValueRef.current,
                max: maximumValueRef.current,
              },
              y: {
                stacked: true,
                ticks: {
                  color: "#444",
                  font: {
                    size: 14,
                    family: '"EMPrint", sans-serif',
                    weight: "bold"
                  }
                },
                grid: {
                  display: false,
                  drawBorder: false
                }
              }
            },
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  font: {
                    size: 14,
                    weight: "bold",
                    family: '"EMPrint", sans-serif',
                    lineHeight: 20
                  },
                  boxWidth: 20,
                  boxHeight: 20,
                  padding: 20,
                  usePointStyle: true
                }
              },
              datalabels: {
                color: '#FFF',
                font: {
                  size: 14,
                  weight: "bold",
                  family: '"EMPrint", sans-serif',
                },
                formatter: (ctx) => {
                  const parsedValue = parseFloat(ctx);
                  if (parsedValue >= 0) return parsedValue;
                  return parsedValue * -1;
                }
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const parsedValue = context?.parsed?.x || 0;
                    if (parsedValue >= 0) return parsedValue;
                    return parsedValue * -1;
                  },
                }
              }
            }
          },
          /* plugins: [ChartDataLabels] */
        }
      );
    }
  }, [data]);

  useEffect(() => {
    if (totalAppointments === undefined) return;
    const labels = ["Confirmadas", "Canceladas", "Pendientes"];
    const workshopAppointments = [totalAppointments.workshop.confirm, totalAppointments.workshop.canceled, totalAppointments.workshop.pending];
    const customerAppointments = [totalAppointments.clients.confirm, totalAppointments.clients.canceled, totalAppointments.clients.pending];
    const maximumValue = [...workshopAppointments, ...customerAppointments].reduce((maximum, current) => {
      if (current > maximum) return current;
      return maximum;
    }, 0);
    maximumValueRef.current = maximumValue;
    setData({
      // X Axis Lables
      labels: labels,
      datasets: [
        {
          label: "Mis citas",
          data: workshopAppointments.map(item => item > 0 ? item * -1 : item),
          backgroundColor: COLORS[0].hex,
          borderColor: COLORS[0].hex,
          order: 1,
        },
        {
          label: "De clientes",
          data: customerAppointments,
          backgroundColor: COLORS[1].hex,
          borderColor: COLORS[1].hex,
          order: 2,
        }
      ]
    })
  }, [totalAppointments]);

  return (
    <section className="graphic-box md-graph-appointment-bars">
      <div className="graphic-container" >
        <canvas ref={canvasRef} />
      </div>
    </section>
  )
}

export default MDGraphAppointmentBars;

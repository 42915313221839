import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import MainHeader from "../../app/Components/Form/MainHeader";
import SasTokenRefresher from "../../app/Components/SasTokenRefresher";
import RequestDashboard from "./RequestDashboard";
import { CarkerRewards } from "../MobilContigo LandingPage/CarkerRewards";
import Sidebar from "./SideBar";
import { Layout, Spin, Modal, Drawer } from "antd";

import { ExxonCallendar } from "../../app/Components/Agenda/Agenda";
import { WorkerDashboard } from "./WorkerDashboard";
import { useDispatch, useSelector } from "react-redux";
import { Workshop, Package, Items } from "shared/src/data-models/Workshop";
import {
  getAction,
  getWorkshops,
  getWorkshopSelected,
  getLoading,
  getError,
  getUser,
  getRecentlyAddedIndex,
  getExpandedMenu,
} from "../../stores/selectors";
import { fetchCatalogs, fetchWorkshops, fetchMe, getWorkshopServices } from "shared";
import {
  CKCloseIcon,
  GarageDeleteIcon,
  TimeRedIcon,
  QuestionIcon,
  ConfettiIcon,
} from "../../assets/SvgIcons";
import { Support } from "./Support";
import Ratings from "./Ratings";
import RatingsByWorkshop from "./RatingsByWorkshop";
import { ConfirmationModal } from "../../app/Components/Modals/ConfirmationModal";
import { ProfileMenu } from "./ProfileMenu";
import { QuizModal } from "../../app/Components/Modals/QuizModal";
import { WorkshopCommitments } from "../MobilContigo LandingPage/WorkshopCommitments";
import Blog from "../MobilContigo LandingPage/Blog";
import PostContent from "../MobilContigo LandingPage/PostContent";
import { AccountSetup } from "../../Screens/AccountSetup/AccountSetup";
import { Disclaimer } from "../LandingPage/Disclaimer";
import AddWorkshop from "../AddWorkshop";
import moment from "moment";
//#endregion

import "./Dashboard.css";
import NewAppointmentForm from "../../app/Components/Dashboard/NewAppointment/NewAppointmentForm";
import { ServicesAPI } from "../../app/Services/axios";
import { WorkshopCommitmentsPage } from "../WorkshopCommitmentsPage/WorkshopCommitmentsPage";
import { AppointmentDetailsPage } from "../AppointmentDetailsPage/AppointmentDetailsPage";
import MainDashboard from "./MainDashboard";
import NotFoundPage from "../NotFoundPage";
import WorkshopFormPage from "../WorkshopFormPage";
import { CkButton, CkMessage } from "../../CkUI";
import { TermsAndConditionsContent } from "../TermsAndConditionsPage/TermsAndConditionsContent";
import { gtmSetId } from "../../app/Utilities";
import { WheelLoader } from "../../app/Components/Loaders";
import MyCustomersPage from "../MyCustomersPage";

const { Content } = Layout;
interface IProps {
  logout: any;
}

const MemoizedDashboard: React.FC<IProps> = ({ logout }) => {
  const history = useHistory();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [viewQuizModal, setviewQuizModal] = useState<boolean>(false);
  const [showModalExit, setShowModalExit] = useState<boolean>(false);
  const [showRulesModal, setShowRulesModal] = useState<boolean>(false);
  const [exitWSForm, setExitWSForm] = useState<boolean>(false);
  const [showInProgressModal, setShowInProgressModal] =
    useState<boolean>(false);
  const [showRetakeModal, setShowRetakeModal] = useState<boolean>(false);
  const [showCongratulationsModal, setShowCongratulationsModal] =
    useState<boolean>(false);

  const [urlKey, setUrlKey] = useState<string>("");
  const [selectedWorkshopPackages, setSelectedWorkshopPackages] = useState<
    Items[]
  >([]);

  const user = useSelector(getUser);

  const onCloseDrawer = useCallback(() => setShowDrawer(false), []);
  const loading: boolean = useSelector(getLoading);
  const [error, errorDescription] = useSelector(getError);
  const [showPhotosModal, setShowPhotosModal] = useState<boolean>(false);
  const [workshopServices, setWorkshopServices] = useState<any[]>([]);

  const [isMec, setIsMec] = useState<boolean>();

  const workshops: Workshop[] = useSelector(getWorkshops);

  const workshopSelected: number = useSelector(getWorkshopSelected);

  const recentlyAddedIndex = useSelector(getRecentlyAddedIndex);

  const workshopAction = useSelector(getAction);

  const expandedMenu = useSelector(getExpandedMenu);

  const match: any = useRouteMatch("/:menu");

  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(fetchWorkshops(true)); // true brings disabled ws. this should be the same for all calls or it could bring inconsistencies
    dispatch(fetchCatalogs("ES-MX"));
  };

  const fetchServices = async (workshopId: string) => {
    if (!workshopId || workshopId === "") return;

    const id = workshops.length > 0 ? workshops[workshopSelected].id! : null;

    if (!id) return;
    const result = await ServicesAPI.getWorkshopCategories(id);

    if (result && result.data) {
      setWorkshopServices(result.data);
    }
  };

  React.useEffect(() => {
    user && fetchData();
  }, []);

  React.useEffect(() => {
    if (workshops.length === 0 || !!user === false) return;
    workshops.length !== user.userRoles.length && updateUserRoles();
  }, [user, workshops.length]);

  /**
   * ===== ROUTES =====
   * here must be listed all the routes in the switch
   */
  React.useEffect(() => {
    if (!user || !workshops[workshopSelected]) return;

    // Scroll to Top on route change
    const main = document.querySelector("main");
    if (main !== null) {
      main.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    }

    const userRole = user.userRoles.filter(
      (current) => current.workshopId === workshops[workshopSelected].id
    )[0];
    const _isMec = userRole === undefined || userRole.roleCode === "MEC";
    setIsMec(_isMec);
    fetchServices(workshops[workshopSelected]?.id as string);

    const routes = [
      /**
       * Here goes all routes for non mecanic role 
       * This routes wont be accesible far mecanic role
       * */
      ...(_isMec === false
        ? [
            "tablero",
            "mis-talleres",
            "mis-clientes",
            "colaboradores",
          ]
        : []),
      /* Here goes the routes for every user with a valid session */
      "blog",
      "citas",
      "chats",
      "soporte",
      "configuracion",
      "compromisos-talleres",
      "recompensas-carker",
      "aviso-legal",
      "calificaciones",
      "usos-de-carker",
      "agregar-cita",
      "agregar-taller",
      "agregar-nuevo-taller",
      "editar-taller",
      "cita",
      "404",
      "terminosycondiciones",
    ];
    
    if (!routes.includes(match.params.menu)) {
      history.replace("/404");
      return;
    }
    if (match.params.menu && main !== null) {
      main.classList.remove("main-disable-scroll");
    }
  }, [user, workshops, match.params.menu]);

  React.useEffect(() => {
    if (error) {
      CkMessage({ type: "error", text: errorDescription });
    } else if (
      !error &&
      errorDescription !== "" &&
      errorDescription !== undefined
    ) {
      if (errorDescription === "submit") {
        CkMessage({
          type: "success",
          text: "Tus respuestas se han enviado correctamente.",
        });

        // window.location.reload();
      }
    }
  }, [error, errorDescription]);

  React.useEffect(() => {
    if (workshops && workshops.length > 0) {
      // isRejected(workshops[0].dsrStatusCode);
      const ws = workshops[workshopSelected];
      const packageset = ws?.packages.reduce((prev: any, curr: Package) => {
        const array = prev;
        const code = curr.servicePackageSet.servicePackageCode;
        const index = array.findIndex((a: any) =>
          Object.keys(a).includes(code)
        );
        const item = {
          type: curr.servicePackageSet.servicePackageTypeCode,
          typeDesc:
            curr.servicePackageSet.servicePackageType.servicePackageTypeDesc,
          price: curr.price,
          description: curr.servicePackageSet.servicePackageMap,
          comment: curr.comment,
          servicePackageSetId: curr.servicePackageSetId,
          packageName: curr.servicePackageSet.servicePackage.servicePackageDesc,
          warnings: curr.servicePackageSet.servicePackageWarnings,
        };
        if (index < 0) {
          array.push({ [code]: [item] });
        } else {
          const set = { ...array[index] };
          set[code] = [...set[code], item];
          array.splice(index, 1, set);
        }
        // [ {code: package}, {code: packages}]
        return array;
      }, []);

      setSelectedWorkshopPackages(packageset);

      if (
        recentlyAddedIndex === undefined &&
        workshopAction[0] !== "" &&
        (workshops[workshopSelected].workshopPhotos === undefined ||
          workshops[workshopSelected].workshopPhotos === null ||
          workshops[workshopSelected].workshopPhotos?.length === 0)
      ) {
        setShowPhotosModal(true);
      }
    }
  }, [workshops, workshopSelected]);

  useEffect(() => {
    workshops.length > 0 && dispatch(getWorkshopServices());
  }, [workshopSelected, workshops]);

  const onSelect = useCallback((evt: any) => {
    if (
      sessionStorage.getItem("talleres_formchanged") &&
      match.params.menu === "talleres"
    ) {
      setUrlKey(evt.key);
      setShowModalExit(true);
    } else {
      sessionStorage.removeItem("talleres_formchanged");
      if (evt.key === "mis-talleres") {
        setExitWSForm(true);
      }
      if (evt.key === "blog") {
        history.push(`/${evt.key}/1`);
      } else {
        history.push(`/${evt.key}`);
      }
      setUrlKey("");
    }
  }, []);

  const redirectUserToHome = () => false;

  useEffect(() => {
    const body = document.querySelector("body");
    if (expandedMenu) {
      body.classList.add("expanded-menu");
    } else {
      body.classList.remove("expanded-menu");
    }
  }, [expandedMenu]);

  const updateUserRoles = useCallback(() => {
    dispatch(fetchMe());
  }, [dispatch]);

  const callendarWorkingHours = useMemo(() => {
    if (!workshops.length) return [];
    return workshops[workshopSelected]?.workingHours.map((workingHour) => {
      if (workingHour.isOpen === false) {
        return {
          dayCode: workingHour.dayCode,
          isOpen: workingHour.isOpen,
          openTime: "",
          closeTime: "",
        };
      }
      if (workingHour.closeTime === "") {
        return {
          dayCode: workingHour.dayCode,
          isOpen: workingHour.isOpen,
          openTime: workingHour.openTime,
          closeTime: moment().endOf("day").format("HH:30Z"),
        };
      }
      return workingHour;
    });
  }, [workshops, workshopSelected]);

  /**
   * Let's make sure that we have everything we need
   * before load the dashboard component
   */
  if (isMec === undefined) return <WheelLoader mode="initial-loader" />;

  /**
   * If the user isn't blocked
   */
  return (
    <Layout
      style={{ height: "100%" }}
      className={[
        "mobile-scroll",
        ...(expandedMenu ? ["expanded-menu"] : [""]),
      ].join(" ")}
    >
      <SasTokenRefresher />
      <Sidebar onSelect={onSelect} isMec={isMec} />
      <Layout>
        <MainHeader
          viewQuizModal={setShowRulesModal}
          logout={logout}
          hideIcons={false}
          dsrStatusCode={
            //  "1" nuevo, "2" en proceso, "3" aceptado, "4" rejected , "5" blacklist.
            workshops.length > 0
              ? workshops[workshopSelected].dsrStatusCode!
              : null
          }
          showInProgressModal={setShowInProgressModal}
          showCongratulationsModal={setShowCongratulationsModal}
          showRetakeModal={setShowRetakeModal}
          type={
            // "MEC"
            user!.userRoles.length > 0 ? user!.userRoles[0].roleCode : "OWN"
          }
        />
        <Content
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: "#fff",
          }}
        >
          {/**
           * all the routes listed below, must be listed on routes effect
           * search for ===== ROUTES =====
           */}
          <Switch>
            <Route path="/404">
              <NotFoundPage />
            </Route>
            <Route path="/agregar-cita">
              <NewAppointmentForm
                hours={callendarWorkingHours}
                workshopServices={workshopServices}
                workshopPackages={selectedWorkshopPackages}
                workshop={
                  workshops.length > 0 ? workshops[workshopSelected] : null
                }
                workshopId={
                  workshops.length > 0 ? workshops[workshopSelected].id! : ""
                }
              />
            </Route>
            <Route path="/cita/:appointmentId">
              <AppointmentDetailsPage
                hours={callendarWorkingHours}
                packages={selectedWorkshopPackages}
                services={workshopServices}
                workshopId={
                  workshops.length > 0 ? workshops[workshopSelected].id! : null
                }
              />
            </Route>
            <Route exact path="/citas">
              {loading ? (
                <Spin
                  spinning={loading}
                  tip="Cargando..."
                  style={{
                    marginTop: "130px",
                    width: "100%",
                    height: "100vh",
                  }}
                />
              ) : workshops.length > 0 ? (
                <ExxonCallendar
                  workshopId={
                    workshops.length > 0
                      ? workshops[workshopSelected].id!
                      : null
                  }
                  coordinates={
                    workshops.length > 0
                      ? `${workshops[
                          workshopSelected
                        ]?.location?.longitude.toString()},${workshops[
                          workshopSelected
                        ]?.location?.latitude.toString()}`
                      : null
                  }
                  hours={callendarWorkingHours}
                  workshopServices={workshopServices}
                  workshopWorkingHours={callendarWorkingHours}
                  packagesList={selectedWorkshopPackages}
                  showDrawer={showDrawer}
                  closeDrawer={onCloseDrawer}
                  viewQuizModal={setShowRulesModal}
                  showInProgressModal={setShowInProgressModal}
                  dsrStatusCode={
                    workshops.length > 0
                      ? workshops[workshopSelected].dsrStatusCode!
                      : null
                  }
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <h2
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontFamily: "Emprint-regular",
                    }}
                  >
                    No perteneces a ningún taller.
                  </h2>
                </div>
              )}
            </Route>
            {/* )} */}

            <Route exact path="/calificaciones">
              <Ratings />
            </Route>
            <Route exact path="/calificaciones/:workshopId([0-9]{1,10})">
              <RatingsByWorkshop />
            </Route>

            <Route exact path={["/chats", "/chats/:selectedId"]}>
              <RequestDashboard />
            </Route>
            <Route exact path="/configuracion/:menu">
              <ProfileMenu />
            </Route>
            <Route exact path={["/editar-taller", "/agregar-nuevo-taller"]}>
              <WorkshopFormPage />
            </Route>
            <Route exact path="/recompensas-carker">
              <div
                style={{
                  marginTop: 0,
                }}
              >
                <CarkerRewards />
              </div>
            </Route>
            <Route exact path="/soporte">
              <div>
                <Support />
              </div>
            </Route>
            <Route exact path="/compromisos-talleres">
              <div>
                <WorkshopCommitments />
              </div>
            </Route>
            <Route exact path="/aviso-legal">
              <Disclaimer />
            </Route>
            <Route exact path="/usos-de-carker">
              <WorkshopCommitmentsPage />
            </Route>
            <Route exact path="/blog/:page([0-9]{1,10})">
              <Blog />
            </Route>
            <Route
              exact
              path="/blog/:category([a-zA-Z-]{1,300})/:urlTitle([a-zA-Z0-9-]{1,300})"
            >
              <PostContent />
            </Route>
            <Route exact path="/terminosycondiciones">
              <TermsAndConditionsContent onDataRetentionClick={() => {}} />
            </Route>
            {isMec === false && (
              <>
                <Route path="/tablero">
                  <MainDashboard />
                </Route>
                <Route path="/mis-talleres">
                  <AccountSetup
                    redirectUserToHome={redirectUserToHome}
                    initialState={0}
                    logout={logout}
                    updateRoles={updateUserRoles}
                    fromWS={true}
                    isMec={isMec}
                    exitWSForm={exitWSForm}
                    setExitWSForm={setExitWSForm}
                  />
                </Route>
                <Route path="/mis-clientes">
                  <MyCustomersPage />
                </Route>
                <Route path="/agregar-taller">
                  <AddWorkshop />
                </Route>
                <Route exact path="/colaboradores">
                  {loading ? (
                    <Spin
                      spinning={loading}
                      tip="Cargando..."
                      style={{ margin: "auto" }}
                    />
                  ) : workshops.length > 0 ? (
                    <WorkerDashboard
                      workshopId={
                        workshops[workshopSelected]
                          ? workshops[workshopSelected].id!
                          : ""
                      }
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <h2
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontFamily: "Emprint-regular",
                        }}
                      >
                        No perteneces a ningún taller.
                      </h2>
                    </div>
                  )}
                </Route>
              </>
            )}
          </Switch>
        </Content>
      </Layout>

      {showModalExit && (
        <ConfirmationModal
          onCancel={() => {
            setShowModalExit(false);
          }}
          onOk={() => {
            sessionStorage.removeItem("talleres_formchanged");
            setShowModalExit(false);
            history.push(urlKey);
          }}
          title="Cerrar sin guardar"
          message="Tiene cambios sin guardar, ¿está seguro que desea salir?"
          visible={showModalExit}
          icon={<GarageDeleteIcon />}
        />
      )}
      {showPhotosModal && (
        <Modal
          open={showPhotosModal}
          onOk={() => setShowPhotosModal(false)}
          onCancel={() => setShowPhotosModal(false)}
          footer={null}
          zIndex={5000}
          className="cta-use-photos-modal"
          closeIcon={<CKCloseIcon onClick={() => setShowPhotosModal(false)} />}
        >
          <h3>Consejo:</h3>
          <p>
            Los clientes prefieren agendar citas en talleres que incluyen fotos.
          </p>
          <p>Actualiza tu taller 🙂</p>
        </Modal>
      )}
      {showRulesModal && (
        <Modal
          open={showRulesModal}
          onClose={() => setShowRulesModal(false)}
          footer={null}
          zIndex={5000}
          className="start-quiz-modal"
          closeIcon={<CKCloseIcon onClick={() => setShowRulesModal(false)} />}
        >
          <h3>
            {/* Take your workshop online */}
            Lleva tu taller en línea
          </h3>
          <p>
            {/* In order to go online to the consumers, please complete the
                following quiz and checklist, and submit your answers. */}
            Para poder estar en línea con los clientes, por favor completa el
            siguiente cuestionario y envía tus respuestas.
          </p>
          <p>
            {/* Once you have started, you will have 60 days to finish. After
                this date I will need to create a new account and start the
                process again. */}
            Tienes 30 días después de registrar tu taller en CarKer para
            completar el cuestionario. Después de esta fecha necesitaras
            contactar a Servicio al Cliente para desbloquear tu cuenta y poder
            compeltar el proceso.
          </p>
          <div className="buttons-container start-quiz-buttons-container">
            <CkButton
              onClick={() => {
                setviewQuizModal(true);
                setShowRulesModal(false);
              }}
              id="HDRLOGIN003"
              triggerGTMOnClick
            >
              Comenzar
            </CkButton>
            <CkButton
              onClick={() => setShowRulesModal(false)}
              variant="link"
              id="HDRLOGIN004"
              triggerGTMOnClick
            >
              Completar más tarde
            </CkButton>
          </div>
        </Modal>
      )}

      {viewQuizModal && (
        <QuizModal
          visible={viewQuizModal}
          setVisible={setviewQuizModal}
          workshopId={
            workshops.length > 0 ? workshops[workshopSelected].id! : "-1"
          }
        />
      )}

      {showInProgressModal && (
        <Modal
          open={showInProgressModal}
          onCancel={() => setShowInProgressModal(false)}
          footer={null}
          zIndex={5000}
          className="start-quiz-modal"
          closeIcon={
            <CKCloseIcon onClick={() => setShowInProgressModal(false)} />
          }
        >
          <TimeRedIcon
            style={{
              color: "var(--primary-color)",
            }}
          />
          <h3>Evaluando respuestas</h3>
          <p>
            Carker se pondrá en contacto contigo en los próximos días para
            continuar con tu proceso de registro. Si tienes dudas contacta a
            nuestro soporte técnico en el 55 2282 0800, mandando Whatsapp al{" "}
            <a
              rel="noopener noreferrer"
              href={"https://wa.me/525564359958"}
              target="_blank"
              id="HDRLOGIN107"
              onClick={(ev) => gtmSetId(ev.currentTarget)}
            >
              55 6435 9958
            </a>{" "}
            o envía un correo a{" "}
            <a
              rel="noopener noreferrer"
              href="mailTo:soporte@carker.com.mx"
              target="_blank"
              id="HDRLOGIN108"
              onClick={(ev) => gtmSetId(ev.currentTarget)}
            >
              soporte@carker.com.mx
            </a>
          </p>
        </Modal>
      )}
      {showRetakeModal && (
        <Modal
          open={showRetakeModal}
          onCancel={() => setShowRetakeModal(false)}
          footer={null}
          className="start-quiz-modal"
          closeIcon={<CKCloseIcon onClick={() => setShowRetakeModal(false)} />}
        >
          <QuestionIcon
            style={{
              color: "var(--primary-color)",
            }}
          />
          <h3>
            {/* Oops, looks like you don’t comply with some item (s) that are
                needed as part of the Contigo program */}
            El equipo de CarKer te contactará pronto
          </h3>
          <p>
            Si tienes alguna duda, contacta a nuestro soporte técnico en el 55
            2282 0800, mandando Whatsapp al
            <a
              rel="noopener noreferrer"
              href={"https://wa.me/525564359958"}
              target="_blank"
            >
              55 6435 9958
            </a>{" "}
            o envía un correo a
            <a
              rel="noopener noreferrer"
              href="mailTo:soporte@carker.com.mx"
              target="_blank"
            >
              soporte@carker.com.mx
            </a>
          </p>
        </Modal>
      )}
      {showCongratulationsModal && (
        <Modal
          open={showCongratulationsModal}
          onCancel={() => setShowCongratulationsModal(false)}
          footer={null}
          zIndex={5000}
          className="start-quiz-modal"
          closeIcon={
            <CKCloseIcon onClick={() => setShowCongratulationsModal(false)} />
          }
        >
          <ConfettiIcon className="orange-filter" />
          <h3>¡Felicitaciones!</h3>
          <p>
            {/* Your answers have been evaluated. Your workshop is now online
                and users are able to contact you and book your services. */}
            Tu taller está ahora en línea y tus usuarios te pueden contactar y
            agendar tus servicios.
          </p>
        </Modal>
      )}
    </Layout>
  );
};

export const Dashboard = React.memo(MemoizedDashboard);

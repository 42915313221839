import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { ColumnsType } from "antd/lib/table";

import { CommunicationAPI } from "../../../../app/Services/axios";
import { Workshop } from "shared/src/data-models/Workshop";
import { Request } from '../../RequestDashboard';
import {
  getWorkshops,
  getWorkshopSelected
} from "../../../../stores";

import {
  ImessagesPreview,
  getVehicleText,
  getClientName
} from "..";

import { CkButton, CkIcon } from '../../../../CkUI';

import "./styles.css";


interface IProps {
  requestingPreview?: boolean;
  messagesPreview: ImessagesPreview;
}
const MDUnreadMessages: FC<IProps> = ({
  requestingPreview,
  messagesPreview
}) => {
  // States
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [requestsList, setRequestsList] = useState<Request[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Selectors
  const workshops: Workshop[] = useSelector(getWorkshops);
  const workshopSelected: number = useSelector(getWorkshopSelected);

  /**
   * Definitions
   */
  const boxTableHeaders: ColumnsType<Request> = [
    {
      title: 'Cliente',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => <Link target="_blank" to={`/chats/${item.id}`}>{getClientName(item)}</Link>,
      ellipsis: true
    },
    {
      title: 'Vehículo',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (_, item) => <p>{getVehicleText(item.vehicle)}</p>,
      ellipsis: true
    }
  ]

  const modalTableHeaders: ColumnsType<Request> = [
    {
      title: 'Cliente',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => <Link target="_blank" to={`/chats/${item.id}`}>{getClientName(item)}</Link>,
      sorter: (a, b) => (a.endConsumer.name + a.endConsumer.lastName).localeCompare(b.endConsumer.name + b.endConsumer.lastName),
      ellipsis: true
    },
    {
      title: 'Vehículo',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (_, item) => <p>{getVehicleText(item.vehicle)}</p>,
      sorter: (a, b) => getVehicleText(a.vehicle).localeCompare(getVehicleText(b.vehicle)),
      ellipsis: true
    }
  ]

  /** 
   * 
   * @param reset if is set to true, pagination will be reseted and the stored conversations in the state will bi ignored
   * @returns 
   */
  const getData = async () => {

    setLoading(true);

    CommunicationAPI.getRequests({
      workshopId: workshops[workshopSelected].id!,
      dateFrom: "",
      dateTo: "",
      requestStatus: "PEND",
      requestType: "",
      orderType: "DESC",
      page: 1,
      pageSize: 0,
    })
      .then((response) => {
        const { data }: { data: any } = response;
        const { requests } = data;

        setRequestsList(requests.sort((a, b) => b.id - a.id));
      })
      .catch((error) => {
        console.error(error);
        setRequestsList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onClose = () => setShowDetails(false);

  /**
   * 
   *  Effects
   * 
   **/
  useEffect(() => {
    showDetails && requestsList.length === 0 && requestsList && getData();
  }, [showDetails]);

  return (
    <section className="dashboard-box md-unread-messages">
      <div className="box-header">
        <h2>Mensajes no respondidos {messagesPreview?.totalmessages && `(${messagesPreview?.totalmessages})`}</h2>
      </div>

      <Table
        className='dashboard-box-table hide-pagiantion'
        columns={boxTableHeaders}
        dataSource={messagesPreview.messages.length > 5 ? messagesPreview.messages.slice(0,5) : messagesPreview.messages}
        footer={undefined}
        loading={requestingPreview}
        pagination={{}}
      />

      <div className="box-footer">
        <CkButton 
          block={false}
          variant="link"
          onClick={() => setShowDetails(true)}
        >
          Ver todo
        </CkButton>
      </div>

      <Modal
        zIndex={5000}
        open={showDetails}
        className="dashboard-modal"
        footer={null}
        closeIcon={<CkIcon name="close" onClick={onClose} />}
        onCancel={onClose}
      >
        <h1>Mensajes no respondidos {messagesPreview?.totalmessages && `(${messagesPreview?.totalmessages})`}</h1>
        <Table
          className='dashboard-box-table'
          columns={modalTableHeaders}
          dataSource={requestsList}
          footer={undefined}
          loading={loading}
          pagination={{ position: ["bottomCenter"] }}
        />
      </Modal>
    </section>
  )
}

export default MDUnreadMessages;

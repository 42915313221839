import React, { FC, useEffect, useState, useRef } from 'react';
import { Checkbox, Modal, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import moment from "moment";

import {
  Appointment,
} from "shared";
import {
  getAppointments
} from "../../../../stores";

import { IVehicle } from '../../../../app/models/Comunication';
import {
  appointmentItemPreview,
  appointmentsPreview,
  getStatusName,
  getPackagesAndServicesText,
  getClientName,
  getVehicleText,
  getDateText
} from '..';

import { CkButton, CkIcon } from '../../../../CkUI';

import "./styles.css";

export const statusCodes = [
  "APPO",
  "RECEP",
  "DIAG",
  "QUOT",
  "PROG"
];

export const statusFilters = [
  {
    value: 'APPO',
    text: 'Cita confirmada',
    label: 'Cita confirmada',
  },
  {
    value: 'RECEP',
    text: 'Recibido',
    label: 'Recibido',
  },
  {
    value: 'DIAG',
    text: 'Diagnosticado',
    label: 'Diagnosticado',
  },
  {
    value: 'QUOT',
    text: 'Cotizado',
    label: 'Cotizado',
  },
  {
    value: 'PROG',
    text: 'Reparando',
    label: 'Reparando',
  }
];

interface IProps {
  requestingAppointments?: boolean;
  requestingPreview?: boolean;
  appointmentsPreview: appointmentsPreview;
  fetchAppointments?: Function;
}
const MDActiveAppointments: FC<IProps> = ({
  requestingAppointments,
  requestingPreview,
  appointmentsPreview,
  fetchAppointments
}) => {
  // Ref
  const tableRef = useRef<HTMLDivElement | undefined>(undefined);

  // States
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [appointmentsList, setAppointmentsList] = useState<any[]>([]);

  // Selectors
  const appointments = useSelector(getAppointments);

  /**
   * Dropdown
   */


  /**
   * Definitions
   */
  const boxTableHeaders: ColumnsType<appointmentItemPreview> = [
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      render: (_, item) => <p className={`--text-${item.workshopApptStatusCode} bold`}>{getStatusName(item.workshopApptStatusCode, statusFilters)}</p>,
      ellipsis: true
    },
    {
      title: 'Cliente',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => <Link target="_blank" to={`/cita/${item.workshopApptId}`}>{[
        ...(item?.userName ? [item.userName] : []),
        ...(item?.userLastName ? [item.userLastName] : [])
      ].join(" ")}</Link>,
      ellipsis: true
    },
    {
      title: 'Vehículo',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (_, item) => <p>{getVehicleText(item.vehicle)}</p>,
      ellipsis: true
    }
  ];

  const modalTableHeaders: ColumnsType<Appointment> = [
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      render: (_, item) => <p className={`--text-${item.statusCode} bold`}>{getStatusName(item.statusCode, statusFilters)}</p>,
      filters: statusFilters,
      onFilter: (value, record) => record.statusCode === value,
      sorter: (a, b) => getStatusName(a.statusCode, statusFilters).localeCompare(getStatusName(b.statusCode, statusFilters)),
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div className="dashboard-box-table-dropdown" onKeyDown={e => e.stopPropagation()}>
          <div className="dropdown-header">
            <p>Filtrar citas:</p>
            <CkButton 
              onClick={close}
              variant='text'
              icon={<CkIcon name="close" 
            />}
            />
          </div>
          <div className="dropdown-options">
            <Checkbox.Group 
              options={statusFilters}
              value={selectedKeys}
              onChange={(checkedValues: CheckboxValueType[]) => setSelectedKeys(checkedValues)} />
          </div>
          <div className="dropdown-actions">
            <CkButton 
              onClick={() => {
                confirm();
                close();
              }}
            >
              Aplicar
            </CkButton>
            <CkButton 
              variant="secondary"
              onClick={() => {
                setSelectedKeys([]);
                confirm();
                close();
              }}
            >
              Limpiar
            </CkButton>
          </div>
        </div>
      )
    },
    {
      title: 'Cliente',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => <Link target="_blank" to={`/cita/${item.id}`}>{getClientName(item)}</Link>,
      sorter: (a, b) => (a.endConsumer.name + a.endConsumer.lastName).localeCompare(b.endConsumer.name + b.endConsumer.lastName),
      ellipsis: true
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (_, item) => <p>{getPackagesAndServicesText(item)}</p>,
      sorter: (a, b) => getPackagesAndServicesText(a).localeCompare(getPackagesAndServicesText(b)),
      ellipsis: true
    },
    {
      title: 'Vehículo',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (_, item) => <p>{getVehicleText(item.vehicle as IVehicle)}</p>,
      sorter: (a, b) => getVehicleText(a.vehicle as IVehicle).localeCompare(getVehicleText(b.vehicle as IVehicle)),
      ellipsis: true
    },
    {
      title: 'Fecha y hora',
      dataIndex: 'date',
      key: 'date',
      render: (_, item) => <p>{getDateText(item.startDateTime)}</p>,
      sorter: (a, b) => moment(a.startDateTime).diff(b.startDateTime),
      ellipsis: true
    }
  ];

  const getData = async () => {
    setLoading(true);
    const filteredAppointments = appointments
      .filter((appointment) => statusCodes.includes(appointment.statusCode))
      .sort((a, b) => moment(b.startDateTime).diff(a.startDateTime));
    setAppointmentsList(filteredAppointments);
    setLoading(false);
  }

  const onClose = () => setShowDetails(false);

  const OpenModalButton: FC<{ displayFor: "desktop" | "mobile" }> = ({ displayFor }) => (
    <CkButton
      block={false}
      className={`--${displayFor}`}
      variant="link"
      onClick={() => setShowDetails(true)}
      disabled={requestingAppointments || fetchAppointments === undefined}
    >
      Ver todo
    </CkButton>);

  /**
   * 
   *  Effects
   * 
   **/
  useEffect(() => {
    appointments && getData();
  }, [appointments]);

  useEffect(() => {
    showDetails && appointments.length === 0 && requestingAppointments === false && fetchAppointments && fetchAppointments();
  }, [showDetails])

  return (
    <section className="dashboard-box md-active-appointments">
      <div className="box-header">
        <h2>Citas activas {appointmentsPreview.totalAppointments && `(${appointmentsPreview.totalAppointments})`}</h2>
        <OpenModalButton displayFor="desktop" />
      </div>

      <Table
        className='dashboard-box-table hide-pagiantion'
        columns={boxTableHeaders}
        dataSource={appointmentsPreview.appointments}
        footer={undefined}
        loading={requestingPreview}
        pagination={{}}
      />

      <div className="box-footer">
        <OpenModalButton displayFor="mobile" />
      </div>

      <Modal
        zIndex={5000}
        open={showDetails}
        className="dashboard-modal"
        footer={null}
        closeIcon={<CkIcon name="close" onClick={onClose} />}
        onCancel={onClose}
      >
        <h1>Citas activas {appointmentsPreview.totalAppointments && `(${appointmentsPreview.totalAppointments})`}{/* {appointmentsList.length > 0 && `(${appointmentsList.length})`} */}</h1>

        <Table
          ref={tableRef}
          className='dashboard-box-table'
          columns={modalTableHeaders}
          dataSource={appointmentsList}
          footer={undefined}
          loading={loading || requestingAppointments}
          pagination={{ position: ["bottomCenter"] }}
          tableLayout='fixed'
          getPopupContainer={(triggerNode) => tableRef.current}
        />
      </Modal>
    </section>
  )
}

export default MDActiveAppointments;

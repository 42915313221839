import React, { FC, Fragment, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";

import MDActions from "./MDActions";
import MDGeneralStats from "./MDGeneralStats";
import MDUnreadMessages from "./MDUnreadMessages";
import MDActiveAppointments from "./MDActiveAppointments";
import MDPendingAppointments from "./MDPendingAppointments";
import MDAppointmentStats from "./MDAppointmentStats";

import MDGraphIncomeInLines from "./MDGraphIncomeInLines";
import MDGraphIncomeInCircles from "./MDGraphIncomeInCircles";
import MDGraphAppointmentBars from "./MDGraphAppointmentBars";

import {
  setCurrentSection,
  getWorkshops,
  getWorkshopSelected,
  getAppointments,
} from "../../../stores";

import { Workshop } from "shared/src/data-models/Workshop";
import { Appointment, fetchAppointments } from "shared";
import { IVehicle } from "../../../app/models/Comunication";
import { WorkshopAPI } from "../../../app/Services/axios";
import { CommunicationAPI } from "../../../app/Services/axios";
import { Request } from "../RequestDashboard";

import { statusCodes as activeAppointmentsStatusCodes } from "./MDActiveAppointments";
import { statusCodes as pendingAppointmentsStatusCodes } from "./MDPendingAppointments";

import "./styles.css";
import { CkButton } from "../../../CkUI";
import { tierCodeToDesc } from "../../../app/Utilities";

export const COLORS = [
  { rgba: (opacity: number) => `rgb(0, 20, 80, ${opacity})`, hex: "#001450" },
  {
    rgba: (opacity: number) => `rgb(35, 105, 189, ${opacity})`,
    hex: "#2369BD",
  },
  { rgba: (opacity: number) => `rgb(213, 0, 10, ${opacity})`, hex: "#D5000A" },
  { rgba: (opacity: number) => `rgb(247, 2, 201, ${opacity})`, hex: "#F702C9" },
  {
    rgba: (opacity: number) => `rgb(151, 71, 255, ${opacity})`,
    hex: "#9747FF",
  },
  { rgba: (opacity: number) => `rgb(6, 156, 138, ${opacity})`, hex: "#069C8A" },
  { rgba: (opacity: number) => `rgb(4, 125, 163, ${opacity})`, hex: "#047DA3" },
  { rgba: (opacity: number) => `rgb(1, 58, 125, ${opacity})`, hex: "#013A7D" },
  { rgba: (opacity: number) => `rgb(183, 1, 110, ${opacity})`, hex: "#B7016E" },
];

export const BASE_CHART_OPTIONS = {
  responsive: true,
  color: "#444",
  plugins: {
    legend: {
      position: "right",
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 14,
          weight: "bold",
          family: '"EMPrint", sans-serif',
        },
      },
    },
  },
};

/**
 * This function parse a number to currency format
 * @param price number
 * @returns string
 */
export const parseCurrency = (price: number): string => {
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 0,
  }).format(price);
};

/**
 * This function parse a number to currency format
 * @param price number
 * @returns string
 */
export const parsePercentage = (percentage: number): string => `%${percentage}`;

/**
 * Return data for datatables
 */
export const getStatusName = (
  statusCode: string,
  statusFilters: {
    value: string;
    text: string;
  }[]
) => statusFilters.find((status) => status.value === statusCode)?.text || "";

export const getPackagesAndServicesText = (appointment: Appointment) => {
  console.info("appointment", appointment);
  const customService = Array.isArray(appointment.customServices)
    ? appointment.customServices[0]
    : undefined;

  if (customService) {
    return [
      customService.workshopServiceName,
      ...(customService.serviceTiers && customService.serviceTiers[0]
        ? [tierCodeToDesc(customService.serviceTiers[0].servicePackageTypeCode)]
        : []),
    ].join(" - ");
  } else {
    return [
      ...(appointment.packages
        ? appointment.packages.map((pack) => pack.servicePackageDesc)
        : []),
      ...(appointment.services
        ? appointment.services.map((service) => service.serviceName)
        : []),
      ...(appointment.serviceTypes
        ? appointment.serviceTypes.map(
            (serviceType) => serviceType.serviceTypeName
          )
        : []),
    ].join("/");
  }
};

export const getClientName = (appointment: Appointment) => {
  if (appointment === null || appointment === undefined) return " ";
  if (
    appointment?.endConsumer === null ||
    appointment?.endConsumer === undefined
  )
    return "Usuario eliminado";
  return [
    ...(appointment.endConsumer.name ? [appointment.endConsumer.name] : []),
    ...(appointment.endConsumer.lastName
      ? [appointment.endConsumer.lastName]
      : []),
  ].join(" ");
};

export const getVehicleText = (vehicle: IVehicle) => {
  if (vehicle === undefined || vehicle === null) return "-";
  return [
    ...(vehicle.brandName ? [vehicle.brandName] : []),
    ...(vehicle.modelCode ? [vehicle.modelCode] : []),
    ...(vehicle.year ? [vehicle.year] : []),
  ].join(" ");
};

export const getDateText = (startDateTime: string) =>
  moment(startDateTime).format("DD/MM/YY hh:mm a");

export const parseMinutesToHours = (minutes: number): string => {
  if (isNaN(minutes) || minutes < 0) return "0h";
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}min`;
};

export const parseNumberWithSuffix = (number: number): string => {
  if (isNaN(number)) return "$0";

  if (number >= 1000000) {
    const millions = (number / 1000000).toFixed(2);
    return `$${millions}m`;
  } else if (number >= 1000) {
    const thousands = (number / 1000).toFixed(2);
    return `$${thousands}k`;
  }
  return `$${number}`;
};

interface IDashboardStaticsStats {
  workshopRating?: string | number;
  totalClients?: string | number;
  completedAppointments?: string | number;
  totalSales?: string | number;
  avgConfimationTime?: string | number;
  avgResponseTime?: string | number;
}
interface IDashboardDynamicStats {
  workshopRating?: string | number;
  totalClients?: string | number;
  completedAppointments?: string | number;
  totalSales?: string | number;
  avgConfimationTime?: string | number;
  avgResponseTime?: string | number;
}

export interface appointmentItemPreview {
  workshopApptId: number;
  workshopApptStatusCode: string;
  workshopApptStartDateTime: string;
  userId: number;
  userName: string;
  userLastName: string;
  vehicle: IVehicle;
}
export interface appointmentsPreview {
  appointments: appointmentItemPreview[];
  totalAppointments: number | undefined;
}

export interface messagesItemPreview {
  endConsumerRequestId: number;
  userName: string;
  userLastName: string;
  vehicle: IVehicle;
}

export interface ImessagesPreview {
  messages: Request[];
  totalmessages: number | undefined;
}

export interface ISaleItem {
  type: string;
  saleTotal: number;
}
export interface IsalesPackagesByDate {
  salesDate: string;
  salesDetail: ISaleItem[];
}
export interface IsalesServicesByDate {
  salesDate: string;
  salesDetail: ISaleItem[];
}
export interface IsalesByPackage {
  type: string;
  saleTotal: number;
}

export interface IsalesByService {
  type: string;
  saleTotal: number;
}

export interface ITotalAppointments {
  workshop: {
    canceled: number;
    confirm: number;
    pending: number;
  };
  clients: {
    canceled: number;
    confirm: number;
    pending: number;
  };
}

interface IProps {}
const MainDashboard: FC<IProps> = ({}) => {
  const dispatch = useDispatch();
  const workshops: Workshop[] = useSelector(getWorkshops);
  const workshopSelected: number = useSelector(getWorkshopSelected);
  const appointments = useSelector(getAppointments);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);

  const [workshopId, setWorkshopId] = useState<string>();

  const [previewPendingAppointments, setPreviewPendingAppointments] =
    useState<appointmentsPreview>({
      appointments: [],
      totalAppointments: undefined,
    });
  const [previewActiveAppointments, setPreviewActiveAppointments] =
    useState<appointmentsPreview>({
      appointments: [],
      totalAppointments: undefined,
    });
  const [previewUnreadMessages, setPreviewUnreadMessages] =
    useState<ImessagesPreview>({ messages: [], totalmessages: undefined });

  const [staticStats, setStaticStats] = useState<IDashboardStaticsStats>();
  const [dinamycStats, setDinamycStats] = useState<IDashboardDynamicStats>();
  const [requestingAppointments, setRequestingAppointments] =
    useState<boolean>(false);
  const [requestingPreview, setRequestingPreview] = useState<boolean>(true);
  const [requestingResults, setRequestingResults] = useState<boolean>(true);

  const [dateTo, setDateTo] = useState<Moment>();
  const [dateFrom, setDateFrom] = useState<Moment>();
  const [salesPackagesByDate, setSalesPackagesByDate] = useState<
    IsalesPackagesByDate[]
  >([]);
  const [salesServicesByDate, setSalesServicesByDate] = useState<
    IsalesServicesByDate[]
  >([]);
  const [salesByPackage, setSalesByPackage] = useState<IsalesByPackage[]>([]);
  const [salesByService, setSalesByService] = useState<IsalesByService[]>([]);
  const [totalAppointments, setTotalAppointments] =
    useState<ITotalAppointments>();

  const getDataPreview = async () => {
    if (workshopId === undefined) return;
    setRequestingPreview(true);

    const dashboardData = await WorkshopAPI.getDashboard(workshopId)
      .then((response) => response.data)
      .catch(() => false);

    const messagesRequest = await CommunicationAPI.getRequests({
      workshopId: workshops[workshopSelected].id!,
      dateFrom: "",
      dateTo: "",
      requestStatus: "PEND",
      requestType: "",
      orderType: "DESC",
      page: 1,
      pageSize: 5,
    })
      .then((response) => response.data)
      .catch(() => undefined);

    setPreviewUnreadMessages({
      messages: messagesRequest
        ? messagesRequest?.requests.sort((a, b) => b.id - a.id)
        : [],
      totalmessages: messagesRequest ? messagesRequest?.totalRequests : 0,
    });

    setPreviewPendingAppointments({
      appointments: dashboardData?.pendingAppointments || [],
      totalAppointments: dashboardData?.totalPendingAppointments || 0,
    });
    setPreviewActiveAppointments({
      appointments: dashboardData?.activeAppointments || [],
      totalAppointments: dashboardData?.totalActiveAppointments || 0,
    });

    setStaticStats({
      workshopRating:
        dashboardData && dashboardData?.workshopRating
          ? dashboardData?.workshopRating
          : 0,
      totalClients:
        dashboardData && dashboardData?.totalClients
          ? dashboardData?.totalClients
          : 0,
    });

    setRequestingPreview(false);
  };

  const getDataResults = async () => {
    if (workshopId === undefined) return;

    setRequestingResults(true);

    const _dateTo = moment(moment().endOf("day")); // moment(/* moment().subtract(1, 'days').format("YYYY-MM-DD") */);
    const _dateFrom = moment(_dateTo).subtract(30, "days");

    const dashboardData = await WorkshopAPI.getDashboardResults(
      workshopId,
      _dateFrom.toISOString(),
      _dateTo.toISOString()
    )
      .then((response) => response.data)
      .catch(() => false);

    setTotalAppointments({
      workshop: {
        canceled:
          dashboardData && dashboardData?.workshopCanceledAppointments
            ? dashboardData?.workshopCanceledAppointments
            : 0,
        confirm:
          dashboardData && dashboardData?.workshopConfirmAppointments
            ? dashboardData?.workshopConfirmAppointments
            : 0,
        pending:
          dashboardData && dashboardData?.workshopPendingAppointments
            ? dashboardData?.workshopPendingAppointments
            : 0,
      },
      clients: {
        canceled:
          dashboardData && dashboardData?.clientCanceledAppointments
            ? dashboardData?.clientCanceledAppointments
            : 0,
        confirm:
          dashboardData && dashboardData?.clientConfirmAppointments
            ? dashboardData?.clientConfirmAppointments
            : 0,
        pending:
          dashboardData && dashboardData?.clientPendingAppointments
            ? dashboardData?.clientPendingAppointments
            : 0,
      },
    });

    /**
     * Pie chart
     */
    setSalesByPackage(
      dashboardData && dashboardData?.salesByPackage
        ? dashboardData?.salesByPackage
        : []
    );
    setSalesByService(
      dashboardData && dashboardData?.salesByService
        ? dashboardData?.salesByService
        : []
    );

    /**
     * Lines charts
     */
    setSalesPackagesByDate(
      dashboardData && dashboardData?.salesPackagesByDate
        ? dashboardData?.salesPackagesByDate
        : []
    );
    setSalesServicesByDate(
      dashboardData && dashboardData?.salesServicesByDate
        ? dashboardData?.salesServicesByDate
        : []
    );
    setDateTo(_dateTo);
    setDateFrom(_dateFrom);

    /**
     * Orange boxes
     */
    setDinamycStats({
      completedAppointments:
        dashboardData && dashboardData?.completedAppointments
          ? dashboardData?.completedAppointments
          : 0,
      totalSales: parseNumberWithSuffix(
        dashboardData && dashboardData?.totalSales
          ? dashboardData?.totalSales
          : 0
      ),
      avgConfimationTime: parseMinutesToHours(
        dashboardData &&
          dashboardData?.averageAppointmentConfirmationTimeMinutes
          ? dashboardData?.averageAppointmentConfirmationTimeMinutes
          : 0
      ),
      avgResponseTime: parseMinutesToHours(
        dashboardData && dashboardData?.averageMessageResponseTimeMinutes
          ? dashboardData?.averageMessageResponseTimeMinutes
          : 0
      ),
    });

    setRequestingResults(false);
  };

  const getAllAppointments = async () => {
    if (workshopId === undefined) return;
    setRequestingAppointments(true);
    dispatch(
      fetchAppointments({
        workshopId: workshopId,
        statusCode: [
          ...pendingAppointmentsStatusCodes,
          ...activeAppointmentsStatusCodes,
        ].join(","),
      })
    ).then((response) => setRequestingAppointments(false));
  };

  const clickHandler = (keyObj: { key: string }) => {
    //console.info("keyObj", keyObj);
    //onChange(keyObj);
    //openExtraOptions && setOpenExtraOptions(false);
  };

  /**
   *
   *  Effects
   *
   **/
  useEffect(() => {
    workshops &&
      workshops[workshopSelected] &&
      workshops[workshopSelected].id &&
      setWorkshopId(workshops[workshopSelected].id);
  }, [workshopSelected, workshops]);

  useEffect(() => {
    workshopId && getDataPreview();
    workshopId && getDataResults();
    workshopId && appointments.length > 0 && getAllAppointments();
  }, [workshopId]);

  useEffect(() => {
    dispatch(setCurrentSection(""));
  }, []);

  useEffect(() => {
    setIsEnabled(workshops[workshopSelected].isEnabled);
  }, [workshopSelected]);

  return (
    <section
      className="main-dashboard"
      style={!isEnabled ? { height: "100%" } : {}}
    >
      <MDActions />
      {!isEnabled && (
        <div
          className="dashboard-box"
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          <p style={{ fontSize: "20px" }}>
            Tu taller se encuentra inhabilitado. Contáctanos para más
            información.
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CkButton
              href="https://wa.me/+525564359958"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => clickHandler({ key: "whatsapp" })}
              variant="primary"
              color="primary"
              icon="whatsapp-outline"
              className="dashboard-whatsapp-button"
            >
              Escribir por WhatsApp
            </CkButton>
            <CkButton
              href="tel:5522820800"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => clickHandler({ key: "llamar" })}
              variant="primary"
              color="primary"
              icon="phone"
              className="dashboard-call-button"
            >
              Llamar al 55 2282 0800
            </CkButton>
            <CkButton
              href="mailto:soporte@carker.com.mx"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => clickHandler({ key: "correo" })}
              variant="primary"
              color="primary"
              icon="envelop"
              className="dashboard-email-button"
            >
              Enviar correo electrónico
            </CkButton>
          </div>
        </div>
      )}
      {isEnabled && (
        <Fragment>
          <Row>
            <Col xs={24} lg={12} xl={10}>
              <MDGeneralStats
                workshopRating={staticStats && staticStats.workshopRating}
                totalClients={staticStats && staticStats.totalClients}
              />
              <MDUnreadMessages
                messagesPreview={previewUnreadMessages}
                requestingPreview={requestingPreview}
              />
            </Col>
            <Col xs={24} lg={12} xl={14}>
              <MDPendingAppointments
                requestingAppointments={requestingAppointments}
                requestingPreview={requestingPreview}
                appointmentsPreview={previewPendingAppointments}
                fetchAppointments={workshopId ? getAllAppointments : undefined}
              />
              <MDActiveAppointments
                requestingAppointments={requestingAppointments}
                requestingPreview={requestingPreview}
                appointmentsPreview={previewActiveAppointments}
                fetchAppointments={workshopId ? getAllAppointments : undefined}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <MDAppointmentStats
                completedAppointments={
                  dinamycStats && dinamycStats.completedAppointments
                }
                totalSales={dinamycStats && dinamycStats.totalSales}
                avgConfimationTime={
                  dinamycStats && dinamycStats.avgConfimationTime
                }
                avgResponseTime={dinamycStats && dinamycStats.avgResponseTime}
              />
            </Col>
            <Col span={24}>
              <MDGraphIncomeInLines
                dateTo={dateTo}
                dateFrom={dateFrom}
                salesPackagesByDate={salesPackagesByDate}
                salesServicesByDate={salesServicesByDate}
                requestingResults={requestingResults}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} xl={12}>
              <MDGraphIncomeInCircles
                salesByPackage={salesByPackage}
                salesByService={salesByService}
                requestingResults={requestingResults}
              />
            </Col>
            <Col xs={24} xl={12}>
              <MDGraphAppointmentBars
                totalAppointments={totalAppointments}
                requestingResults={requestingResults}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </section>
  );
};

export default MainDashboard;

import React, { FC, useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { ChartData, ChartItem } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Select } from 'antd';

import { 
  COLORS,
  parseCurrency,
  IsalesByPackage,
  IsalesByService
} from '..';
import { PackagesAPI, ServicesAPI } from '../../../../app/Services/axios';

import "./styles.css";

interface IServiceType {
  serviceTypeCode: string;
  serviceTypeName: string;
  iconPath: string;
  htmlId: string;
  relatedFailures: {
    srvcTypeFailRelatedId: string[];
    srvcTypeFailRelatedDesc: string[];
  }[]
}
interface IPackage {
  servicePackageSetId: number;
  servicePackageCode: string;
  servicePackageTypeCode: string;
  servicePackageWarnings: string;
  servicePackageMap: {
    servicePackageSetId: number;
    packageItemCode: string;
    packageItemDesc: string;
  }[],
  servicePackageType: {
      servicePackageTypeCode: string;
      servicePackageTypeDesc: string;
      packageItems: null
  },
  servicePackage: {
      servicePackageCode: string;
      servicePackageDesc: string;
      servicePackageDescHtml: string;
      iconPath: string;
      warnings: string;
      sortingValue: number;
      isActive: boolean;
  }
}
interface IProps {
  requestingResults: boolean;
  salesByPackage: IsalesByPackage[];
  salesByService: IsalesByService[];
}
const MDGraphIncomeInCircles: FC<IProps> = ({
  requestingResults,
  salesByPackage,
  salesByService
}) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const chartRef = useRef<ChartItem>();

  const [servicesCatalog, setServicesCatalog] = useState<IServiceType[]>();
  const [packagesCatalog, setPackagesCatalog] = useState<IPackage[]>();
  const [graphicSource, setGraphicSource] = useState<"packages" | "services">();
  const [servicesData, setServicesData] = useState<{
    labels: string[];
    values: number[];
  }>({
    labels: [],
    values: []
  });
  const [packagesData, setPackagesData] = useState<{
    labels: string[];
    values: number[];
  }>({
    labels: [],
    values: []
  });
  const [data, setData] = useState<ChartData>();

  const handleChange = (value: "packages" | "services") => setGraphicSource(value);;

  const getData = () => {
    setData({
      // X Axis Lables
      labels: graphicSource === "packages" ? packagesData.labels : servicesData.labels,
      datasets: [
        {
          data: graphicSource === "packages" ? packagesData.values : servicesData.values,
          backgroundColor: COLORS.map(color => color.hex),
          borderColor: COLORS.map(color => color.hex),
          borderAlign: "inner",
          /* order: 0,
          hoverOffset: 5 */
        }
      ]
    });
  }

  const getCatalog = async () => {
    const requestServices = await ServicesAPI.getAllServices('ES-MX').then(response => response.data).catch(() => false);
    requestServices && setServicesCatalog(requestServices);

    const requestPackages = await PackagesAPI.getPackagesDetail().then(response => response.data).catch(() => false);
    requestPackages && setPackagesCatalog(requestPackages); 
  }

  useEffect(() => {
    if (servicesData && servicesData.labels.length > 0 && graphicSource === undefined) {
      setGraphicSource("services");
    }
    if (packagesData && packagesData.labels.length > 0 && graphicSource === undefined) {
      setGraphicSource("packages");
    }
  }, [servicesData, packagesData])

  useEffect(() => {
    if (servicesCatalog && salesByService) {
      const labels: string[] = [];
      const values: number[] = [];
      salesByService.forEach((item) => {
        const service = servicesCatalog.find((service) => service.serviceTypeCode === item.type)
        if (service) {
          values.push(item.saleTotal);
          labels.push(service.serviceTypeName);
        }
      });
      setServicesData({
        labels: labels,
        values: values
      });
    }
  }, [servicesCatalog, salesByService]);

  useEffect(() => {
    if (packagesCatalog && salesByPackage) {
      const labels: string[] = [];
      const values: number[] = [];
      salesByPackage.forEach((item) => {
        const pack = packagesCatalog.find((pack) => pack.servicePackageCode === item.type);
        if (pack) {
          values.push(item.saleTotal);
          labels.push(pack.servicePackage.servicePackageDesc);
        }
      });
      setPackagesData({
        labels: labels,
        values: values
      });
    }
  }, [packagesCatalog, salesByPackage]);

  useEffect(() => {
    if (data && canvasRef.current) {
      if (chartRef.current !== undefined) {
        chartRef.current.destroy && chartRef.current.destroy();
      }
      chartRef.current = new Chart(
        canvasRef.current,
        {
          type: 'pie',
          data: data,
          options: {
            layout: {
              padding: 0
            },
            aspectRatio: 16/9,
            color: "#444",
            font: {
              size: 14,
              family: '"EMPrint", sans-serif',
            },
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  // This more specific font property overrides the global property
                  font: {
                    size: 14,
                    weight: "bold",
                    family: '"EMPrint", sans-serif',
                    lineHeight: 20
                  },
                  boxWidth: 20,
                  boxHeight: 20,
                  padding: 20,
                  usePointStyle: true
                }
              },
              datalabels: {
                color: '#FFF',
                font: {
                  size: 14,
                  weight: "bold",
                  family: '"EMPrint", sans-serif',
                },
                formatter: (ctx) => parseCurrency(parseFloat(ctx))
              },
              tooltip: {
                callbacks: {
                  label: (context) => parseCurrency(context.parsed),
                }
              }
            }
          },
          /* plugins: [ChartDataLabels] */
        }
      );
    }
  }, [data]);

  useEffect(() => {
    getData();
  }, [graphicSource,salesByService,salesByPackage]);

  useEffect(() => {
    getCatalog();
  }, []);

  return (
    <section className="graphic-box md-graph-income-in-circles">
      <div className="graphic-options">
        <div className="select-container">
          <label htmlFor="">Por:</label>
          <Select
            className='--custom'
            value={graphicSource}
            onChange={handleChange}
            options={[
              {
                value: 'packages',
                label: 'Mantenimientos',
                disabled: packagesData.values.length === 0
              },
              {
                value: 'services',
                label: 'Reparaciones',
                disabled: servicesData.values.length === 0
              },
            ]}
          />
        </div>

      </div>
      <div className="graphic-container">
        <canvas ref={canvasRef} />
      </div>
    </section>
  )
}

export default MDGraphIncomeInCircles;

import React, { FC } from 'react';
import { Skeleton, Tooltip } from 'antd';
import {
  InfoCircleOutlined,
  WarningOutlined
} from '@ant-design/icons';
import "./styles.css";

interface IProps {
  completedAppointments?: string | number;
  totalSales?: string | number;
  avgConfimationTime?: string | number;
  avgResponseTime?: string | number;
}

const MDAppointmentStats: FC<IProps> = ({
  completedAppointments,
  totalSales,
  avgConfimationTime,
  avgResponseTime
}) => {

  const StatsBox = ({
    value,
    label,
    textSize
  }: {
    value?: string | number,
    label: string,
    textSize: "regular" | "medium"
  }) => (
    <div className="stats-box">
      {value === undefined ? 
        <Skeleton active loading paragraph={false} /> :
        <p className={`value ${textSize}`}>{value}</p>}
      <p className="label">{label}</p>
    </div>
  );

  return (
    <section className="md-appointment-stats">
      <h2>
        Mis resultados 
        <Tooltip 
          title="Calculado en base a los últimos 30 días"
        >
          {/* <WarningOutlined /> */}
          <InfoCircleOutlined />
        </Tooltip>
        </h2>
      <div className="boxes-container">
        <StatsBox 
          value={completedAppointments}
          label="Citas completadas"
          textSize={"regular"}
        />
        <StatsBox 
          value={totalSales}
          label="Mis ventas"
          textSize={"medium"}
        />
        <StatsBox 
          value={avgConfimationTime}
          label="T. Promedio de confirmación de citas"
          textSize={"medium"}
        />
        <StatsBox 
          value={avgResponseTime}
          label="T. Promedio de respuesta de mensajes"
          textSize={"medium"}
        />
      </div>
      
    </section>
  )
}

export default MDAppointmentStats;

import React, { FC } from "react";
import { Row, Skeleton } from "antd";
import "./styles.css";
import { CkIcon } from "../../../../CkUI";

interface IProps {
  workshopRating?: string | number;
  totalClients?: string | number;
}
const MDGeneralStats: FC<IProps> = ({ workshopRating, totalClients }) => {
  const StatsBox = ({
    value,
    label,
    onClick,
    showLink
  }: {
    value?: string | number;
    label: string;
    onClick?: () => void;
    showLink?: boolean;
  }) => (
    <div className={`stats-box ${onClick && "pressable"}`} onClick={onClick}>
      {value === undefined ? (
        <Skeleton active loading paragraph={false} />
      ) : (
        <p className="value">{value}</p>
      )}
      <Row>
        <p className="label">{label}</p>
        {showLink && (<CkIcon style={{ marginLeft: "5px", marginTop: "7px"}} name="link-text" width="" height="" fill="fff"/>)}
      </Row>

    </div>
  );

  return (
    <section className="md-general-stats">
      <StatsBox value={workshopRating} label="Mis calificaciones" />
      <StatsBox
        value={totalClients}
        label="Mis clientes"
        onClick={() => (window.location.href = "/mis-clientes")}
        showLink={true}
      />
    </section>
  );
};

export default MDGeneralStats;
